import React from 'react';
import {Column} from 'primereact/column';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {Tooltip} from 'primereact/tooltip';
import {InputText} from 'primereact/inputtext';
import {
  AppColumnMenuBodyTemplate,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  UsersService,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import {FreightOrder, QueryParameter, Run, Stop, StopAggregate, Task, User} from 'two-core';
import {
  DataTablePageParams,
  DataTableSortParams,
  DataTableSortOrderType,
  DataTableRowReorderParams,
} from 'primereact/datatable';
import StopsService from '../../services/StopsService';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import EditTasksDialog from './EditTasksDialog';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import {config} from '../../config/config';
import FreightOrdersService from '../../services/FreightOrdersService';
import {MultiSelect} from 'primereact/multiselect';
import {DropdownChangeParams} from 'primereact/dropdown';
import {AddEditProofDialog} from '../Proof/AddEditProofDialog/AddEditProofDialog';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera} from '@fortawesome/pro-regular-svg-icons';

export const stages: string[] = ['Planned', 'In Progress', 'Done'];

interface Props {
  id?: string;
  heightToScroll?: string;
  run: Run;
  customMenuItems?: (selectedStops: Stop[]) => AppMenuItem[];
  hidePaging?: boolean;
}

interface State {
  loading: boolean;
  items: Stop[];
  selectedItems: Stop[];
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  filters: {
    location: string;
    stage: string;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  users: User[];
  freightOrders: FreightOrder[];
  showEditStopTasksDialog: boolean;
  showAddEditProofDialog: boolean;
}

class StopListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  stopsService: StopsService | null = null;
  usersService: UsersService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      filters: {
        location: '',
        stage: '',
      },
      sortBy: {field: 'line_up', order: 1},
      users: [],
      freightOrders: [],
      showEditStopTasksDialog: false,
      showAddEditProofDialog: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.stopBodyTemplate = this.stopBodyTemplate.bind(this);
    this.tasksBodyTemplate = this.tasksBodyTemplate.bind(this);
    this.doneBodyTemplate = this.doneBodyTemplate.bind(this);
    this.onRowReorder = this.onRowReorder.bind(this);
    this.onProofButtonClick = this.onProofButtonClick.bind(this);
    this.proofBodyTemplate = this.proofBodyTemplate.bind(this);
  }

  async componentDidMount() {
    this.stopsService = this.context.stopsService;
    this.usersService = this.context.usersService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.stopUpdate || message === messages.taskUpdate || message === messages.proofChanged) {
        this.loadData();
        this.loadFreightOrders();
      }
    });

    this.loadData();
    this.loadFreightOrders();
    this.loadUsers();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run_id',
        value: this.props.run.id,
      })
    );

    if (this.state.filters.location) {
      filters.push(
        JSON.stringify({
          field: 'location.name',
          value: this.state.filters.location,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    let sortByField = this.state.sortBy?.field ?? 'line_up';
    if (sortByField === 'stop_location.name') {
      sortByField = 'location.name';
    }

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const aggregate: StopAggregate[] = ['stop_location', 'tasks', 'proof'];
    const params: QueryParameter = {
      offset: this.props.hidePaging ? undefined : this.state.pagination.offset,
      page_size: this.props.hidePaging ? undefined : this.state.pagination.pageSize,
      filters,
      aggregate,
      orderBys: sortBy,
    };

    this.stopsService
      ?.getStops(params)
      .then(data => {
        const dataRecords = (data?.records as Stop[]) ?? [];
        this.handleSelectedItems(dataRecords);

        this.setState({
          items: dataRecords,
          totalItems: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
      });
  }

  loadFreightOrders() {
    const runId = this.props.run.id;

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'run.id',
        value: runId,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];

        this.setState({
          freightOrders: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      aggregate: false,
    };
    this.usersService
      ?.getUsers(params)
      .then(data => {
        const dataRecords = (data?.records as User[]) ?? [];

        this.setState({
          users: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
      });
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    if (selectedItemsCount > 0) {
      return this.props.customMenuItems
        ? this.props.customMenuItems(selectedItems)
        : this.initOtherMenuItems(selectedItemsCount);
    }
    return [];
  }

  initOtherMenuItems(selectedItemsCount: number): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const editTasksMenu: AppMenuItem = {
      label: 'Edit Stop Tasks',
      faIcon: ['far', 'pen'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showEditStopTasksDialog();
      },
    };

    const removeMenu: AppMenuItem = {
      label: 'Remove Stop',
      faIcon: ['far', 'minus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.delete();
      },
    };

    if (selectedItemsCount === 1) {
      menuItems.push(editTasksMenu);
    }
    menuItems.push(removeMenu);

    return menuItems;
  }

  showEditStopTasksDialog() {
    this.setState({showEditStopTasksDialog: true});
  }

  async delete() {
    const stops: Stop[] = this.state.selectedItems;
    this.deleteStops(stops);
  }

  async deleteStops(stops: Stop[]) {
    Promise.all(
      stops.map(async (currentStop: Stop) => {
        const id = currentStop?.id?.toString() ?? '';
        const tasks = currentStop?.tasks?.filter(t => t !== null) ?? [];

        if (tasks.length === 0) {
          return this.stopsService
            ?.deleteStop(id)
            .then(() => {
              this.toastService?.showSuccess(this.toast, `Stop ${currentStop?.line_up} deleted successfully.`);
              // remove from selectedItems
              const selectedItems = this.state.selectedItems;
              const remainingSelectedItems = selectedItems?.filter(t => t.id !== currentStop?.id);
              this.setState({selectedItems: remainingSelectedItems});
              //remove from items
              const items = this.state.selectedItems;
              const remainingItems = items?.filter(t => t.id !== currentStop?.id);
              this.setState({items: remainingItems});
            })
            .catch(error => {
              console.error('error: ' + error);
              return Promise.reject(`Sorry, Stop ${currentStop?.line_up} delete failed, please try again.`);
            });
        } else {
          return Promise.reject(`Sorry, Stop ${currentStop?.line_up} with task delete failed.`);
        }
      })
    )
      .then(() => {
        this.loadData();
        MessageService.sendMessage(messages.stopUpdate);
      })
      .catch(reason => {
        this.toastService?.showError(this.toast, reason);
      });
  }

  onRowReorder = (e: DataTableRowReorderParams) => {
    const stops = this.state.items;
    const sortBy = this.state.sortBy;

    if (sortBy?.field === 'line_up' && sortBy?.order === 1) {
      const reorderedStops = e.value as unknown as Stop[];
      const updatedStops: Stop[] = [];
      const dragIndex = e.dragIndex;
      const dropIndex = e.dropIndex;
      const dragStop = stops[dragIndex];
      const dropStop = stops[dragIndex < dropIndex ? dropIndex - 1 : dropIndex];

      const start = dragIndex < dropIndex ? dragIndex : dropIndex;
      const end = dragIndex < dropIndex ? dropIndex : dragIndex + 1;
      const stopsToUpdate = reorderedStops.slice(start, end);

      stopsToUpdate.map(stop => {
        if (stop.id === dragStop.id) {
          const updateStop: Stop = {
            ...stop,
            line_up: dropStop.line_up,
          };
          updatedStops.push(updateStop);
        } else {
          const lineUpStop = {...stop};
          const lineUp = dragIndex < dropIndex ? --lineUpStop.line_up : ++lineUpStop.line_up;
          const updateStop: Stop = {
            ...stop,
            line_up: lineUp,
          };
          updatedStops.push(updateStop);
        }
      });

      this.saveStops(updatedStops);
    } else {
      this.toastService?.showWarn(this.toast, 'Sorry, records schould be sorted by Stop ASC.');
    }
  };

  async saveStops(stops: Stop[]) {
    return Promise.all(
      stops.map((stop: Stop) => {
        const stopId = stop.id?.toString() ?? '';
        return this.stopsService
          ?.updateStop(stopId, stop)
          .then(() => {})
          .catch(error => {
            console.error('error: ' + error);
          });
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Stops updated successfully.');
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Stops update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  handleSelectedItems(allItems: Stop[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Stop[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Stop[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Stop) {
    const items = [item];
    await this.setState({selectedItems: items});
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  removeTasksInStop(tasks: Task[]) {
    if (tasks.length > 0) {
      const task = tasks[0];
      const taskIds = (tasks.map(s => s?.id ?? '0') as string[]) ?? [];
      const stop = this.state.selectedItems.find(s => s.id === task.stop_id);
      const remainingTasks = stop?.tasks?.filter(t => !taskIds.includes(t.id?.toString() ?? '0'));
      const selectedStops = [...this.state.selectedItems];
      const updatedSelectedStops = selectedStops.map(el =>
        el.id === task.stop_id ? {...el, tasks: remainingTasks} : el
      );
      this.setState({selectedItems: updatedSelectedStops});
    }
  }

  stopBodyTemplate(rowData: Stop) {
    const state = localStorage.getItem('current state') ?? '';
    const location = rowData.stop_location;
    let locationName = '';
    if (location) {
      if (location.state_id === state) {
        locationName = location.name;
      } else {
        locationName = `${location.name} ${location.state_id}`;
      }
    }
    const locationType = location?.type?.charAt(0)?.toUpperCase() ?? '';
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        selectedItems={[]}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <span>{`${locationType}: ${locationName}`}</span>
      </AppColumnMenuBodyTemplate>
    );
  }

  stageBodyTemplate(rowData: Stop) {
    return (
      <span className={`stage-badge stop-stage-${(rowData?.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}>
        {rowData?.stage}
      </span>
    );
  }

  tasksBodyTemplate(stop: Stop) {
    const tasks = (stop.tasks?.filter(t => t !== null) ?? []).sort((a, b) => a.line_up - b.line_up);
    const executedTasks = tasks.filter(t => t.executed_on !== null);

    const tooltipElement = (
      <div className="p-d-flex p-flex-column w-100">
        {tasks &&
          tasks.map(task => {
            let executedText = '';

            if (task.executed_on) {
              const user = this.state.users.find(u => u.id === task?.executed_by_id);

              executedText = `${user?.username ?? ''} ${DateTime.fromISO(task.executed_on.toString()).toFormat(
                formats.dateTime
              )}`;
            }

            const freightOrder = this.state.freightOrders.find(o => o.id === task.freight_order_id);

            const order = freightOrder?.order;
            const shipmentItems = freightOrder?.shipment_items?.filter(si => si !== null) ?? [];

            const descriptionText = task.description ?? order?.id ?? '';

            return (
              <div className="p-grid p-p-0 p-mb-1" key={task.id}>
                <span className="p-col-fixed p-p-0 p-text-nowrap p-text-truncate" style={{width: '100px'}}>
                  {`${task.line_up} ${task.type}: `}
                </span>
                <span className="p-col p-p-0 p-text-nowrap p-text-truncate">{`${descriptionText}`}</span>
                <span className="p-col-fixed p-p-0 p-text-nowrap p-text-truncate" style={{width: '80px'}}>
                  {` ${shipmentItems.length} ${shipmentItems.length !== 1 ? ' boxes' : ' box'} `}
                </span>
                <span className="p-col-fixed p-p-0 p-text-nowrap p-text-truncate" style={{width: '220px'}}>
                  {`${executedText}`}
                </span>
              </div>
            );
          })}
      </div>
    );

    return (
      <>
        <Tooltip className="custom-tooltip" target={`#tasks-${stop.id}`} position="right" style={{width: '800px'}}>
          {tooltipElement}
        </Tooltip>
        <span id={`tasks-${stop.id}`}>{`${executedTasks.length}/${tasks.length}`}</span>
      </>
    );
  }

  doneBodyTemplate(stop: Stop) {
    const tasks = stop.tasks?.filter(t => t !== null) ?? [];

    const notExecutedTasks = tasks.filter(t => t.executed_on === null) ?? [];
    if (notExecutedTasks.length !== 0) {
      return '';
    }

    const lastTask = tasks
      .filter(t => t.executed_on !== null)
      .sort(
        (a, b) =>
          (b.executed_on ? new Date(b.executed_on.toString()).getTime() : 0) -
          (a.executed_on ? new Date(a.executed_on.toString()).getTime() : 0)
      )[0];

    if (!lastTask) {
      return '';
    }

    const lastTaskDate = lastTask.executed_on ? new Date(lastTask.executed_on) : undefined;

    const user = this.state.users.find(u => u.id === lastTask?.executed_by_id);
    const doneValue = lastTaskDate
      ? `${user?.username ?? ''} ${DateTime.fromJSDate(lastTaskDate).toFormat(formats.dateTime)}`
      : '';
    return <span>{doneValue}</span>;
  }

  proofBodyTemplate(stop: Stop) {
    const proof = stop.proof;
    if (proof) {
      const formattedTakenAt = proof.taken_at
        ? DateTime.fromISO(proof.taken_at.toString()).toFormat(formats.dateTime)
        : '';
      return (
        <Button
          className={'p-mt-2 p-button-success'}
          style={{width: '200px'}}
          onClick={() => this.onProofButtonClick(stop)}
          label={formattedTakenAt}
        />
      );
    }
    return (
      <Button
        className={'p-py-1'}
        style={{width: '200px'}}
        icon={<FontAwesomeIcon icon={faCamera} size="2xl" />}
        onClick={() => this.onProofButtonClick(stop)}
      />
    );
  }

  onProofButtonClick(stop: Stop) {
    this.setState(() => ({selectedItems: [stop], showAddEditProofDialog: true}));
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stopTypingDetection);
  };

  render() {
    const {run} = this.props;
    const {showAddEditProofDialog, selectedItems, freightOrders} = this.state;

    const locationFilter = (
      <InputText
        name="location"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const stageSelectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter stop-stage-${value.toLowerCase().replaceAll(' ', '-')}`}
          >
            {value}
          </span>
        );
      }
      return <></>;
    };
    const stageItemTemplate = (option: string) => {
      if (option) {
        return <span className={`stage-badge stop-stage-${option.toLowerCase().replaceAll(' ', '-')}`}>{option}</span>;
      }
      return <></>;
    };
    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={stageSelectedItemTemplate}
        itemTemplate={stageItemTemplate}
        value={this.state.filters.stage}
        options={stages}
        className="form-filter"
        name="stage"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    return (
      <>
        <div id="stop_list_page_container" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            onRowReorder={this.onRowReorder}
            pageSizeIdentifier={this.props.heightToScroll ? undefined : 'stop_list_page_container' + this.props.id}
            heightToScroll={
              this.props.heightToScroll && this.props.heightToScroll !== 'max' ? this.props.heightToScroll : undefined
            }
            sizeIdentifiers={[]}
            loading={this.state.loading}
            value={this.state.items}
            totalRecords={this.state.totalItems}
            activeFilters={{}}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            onPage={e => this.onPageChange(e)}
            onSort={e => this.onSort(e)}
            // selectionMode={'single'}
            selectedItems={[]}
            handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Stop[])}
          >
            <Column rowReorder className={'table-expander'} bodyClassName={'table-expander'} />
            <Column
              header="Stop"
              field="stop_location.name"
              filter
              filterElement={locationFilter}
              sortable
              body={this.stopBodyTemplate}
              showFilterMenu={false}
              style={{width: '200px'}}
            />
            <Column
              header="Stage"
              field="stage"
              filter
              filterElement={stageFilter}
              sortable
              showFilterMenu={false}
              style={{width: '150px'}}
              body={this.stageBodyTemplate}
            />
            <Column header="Tasks" body={this.tasksBodyTemplate} showFilterMenu={false} style={{width: '150px'}} />
            <Column header="Done" body={this.doneBodyTemplate} showFilterMenu={false} style={{width: '150px'}} />
            <Column header="Proof" body={this.proofBodyTemplate} showFilterMenu={false} style={{maxWidth: '200px'}} />
          </TwoDataTable>
          {!this.props.customMenuItems && (
            <EditTasksDialog
              stop={selectedItems[0]}
              toast={this.toast}
              onHide={() => this.setState({showEditStopTasksDialog: false})}
              showDialog={this.state.showEditStopTasksDialog}
              notifyRemovedTasks={tasks => this.removeTasksInStop(tasks)}
            />
          )}
          {selectedItems[0] && (
            <AddEditProofDialog
              showDialog={showAddEditProofDialog}
              onHide={() => this.setState({showAddEditProofDialog: false})}
              stopId={selectedItems[0]!.id!}
              proofId={selectedItems[0].proof?.id}
            />
          )}

          <Toast ref={this.toast} />
        </div>
      </>
    );
  }
}

export default StopListComponent;
