import {FreightOrder, Task} from 'two-core';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ReactNode} from 'react';
import {faChevronDown, faChevronUp, faMessageExclamation} from '@fortawesome/pro-light-svg-icons';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {InputSwitch} from 'primereact/inputswitch';

interface Props {
  disabled: boolean;
  hideActions: boolean;
  proofOrders: FreightOrder[];
  selectedProofOrders: FreightOrder[];
  onAddOrders: () => void;
  onOrderSwitch: (freightOrder: FreightOrder) => void;
}

export const OrdersPanel = ({
  disabled,
  proofOrders,
  selectedProofOrders,
  onAddOrders,
  onOrderSwitch,
  hideActions,
}: Props) => {
  const panelTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = (
      <FontAwesomeIcon icon={options.collapsed ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']} size={'xl'} />
    );

    const className = `${options.className}`;
    const titleClassName = `${options.titleClassName}`;
    const ordersCount = proofOrders.length;
    let boxCount = 0;
    for (const order of proofOrders) {
      boxCount += order.shipment_items?.length ?? 0;
    }

    return (
      <>
        <div className={className}>
          <div className="p-d-flex p-ai-center p-jc-between">
            <div className="p-mr-2" onClick={options.onTogglerClick}>
              {toggleIcon}
            </div>
            <span className={titleClassName + ' p-mr-2 p-as-center'}>Orders {ordersCount}</span>
          </div>
          <div className="p-d-flex p-ai-center p-jc-between">
            <span className={titleClassName + ' p-mr-3'}>Boxes {boxCount}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <Panel toggleable headerTemplate={panelTemplate} className="orders-panel p-mb-2">
      {proofOrders &&
        proofOrders.map((freightOrder, index) => {
          const cardMargin = index !== proofOrders!.length - 1 ? 'p-mb-3' : '';
          const factoryOrder = freightOrder?.factory_order;
          const order = freightOrder?.order;
          const orderShipmentItems = freightOrder?.shipment_items?.filter(si => si && si.order_id === order?.id) ?? [];

          const checked = selectedProofOrders.includes(freightOrder);

          const boxesType =
            factoryOrder?.product_line === 'Colourvue' ? 'cv' : factoryOrder?.product_line === 'Shadesol' ? 'ss' : 'sh';

          const noteElement = freightOrder?.delivery_note?.length ? (
            <FontAwesomeIcon className={'p-mr-1'} icon={faMessageExclamation} />
          ) : undefined;
          return (
            <Card key={'order' + index + freightOrder?.id?.toString()} className={cardMargin}>
              <div className="p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-p-0 p-col-9 p-flex-wrap">
                  {noteElement}
                  <span className={'p-pr-2'}>{freightOrder?.id ?? ''}</span>
                  <span className={'p-pr-2 account-bold'}>{freightOrder?.owner_company?.account_number ?? ''}</span>
                  <span className={'p-pr-2'}>{order?.reference ?? ''}</span>
                </div>
                <div className="p-d-flex p-p-0 p-col-1">
                  <span>{`${orderShipmentItems.length} ${boxesType}`}</span>
                </div>
                {!hideActions && (
                  <InputSwitch checked={checked} onChange={() => onOrderSwitch(freightOrder)} disabled={disabled} />
                )}
              </div>
            </Card>
          );
        })}
      {!hideActions && <Button className="p-mt-3" label="Add Order" onClick={onAddOrders} disabled={disabled} />}
    </Panel>
  );
};
